// App.js
import React, { useState, useEffect } from 'react';
import './css/mainmin.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import Home from './Components/Screens/Home';
import Contactus from './Components/Screens/Contactus';
import Footer from './Components/Screens/Footer';
import Ourproducts from './Components/Screens/Ourproducts';
import About from './Components/Screens/About';
import SafetyandInstructions from './Components/Screens/Safteyandinstructions';
import Appbar from "./Components/Screens/Appbar";

const App = () => {
  const [showAppbar, setShowAppbar] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setShowAppbar(true);
    } else {
      setShowAppbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <>
        {showAppbar && <Appbar />}
        <div style={{ paddingTop: '0px' }}>
          <Routes>
            <Route path="/" element={<><Home /><About /><Ourproducts /><SafetyandInstructions /><Contactus /></>} />
          </Routes>
          <Footer />
        </div>
      </>
    </Router>
  );
}

export default App;
