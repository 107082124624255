import React from "react";
import pdf from "../Assets/Privacy Policy.pdf"

const Footer = () => {
  return (
    <div className="text-center p-0 bg-primary text-light small" id="Footer">
      <div className="p-3 flex justify-between items-center">
        <div className="flex flex-col md:flex-row items-center">
          <span>
            Copyrights © 2024 - Shri Balaji Gas Agencies. Design & Developed by
            <a
              href="https://www.proglobalsolutions.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light text-decoration-none"
            >
              {"  "}<b>Pro Global Solutions</b>
            </a>
          </span>
          <span className="hidden md:inline mx-2">|</span>
          <a
            href={pdf} 
            target="_blank" 
            className="text-light text-decoration-none"
          >
            <b>Privacy Policy</b>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
